<template>
  <v-row>
    <v-col cols="12" sm="2">
      <v-card dark color="accent" outlined class="pa-2" height="100%">
        <v-card-text>
          <v-row>
            <v-select
              :items="canalOptions"
              v-model="canalSelected"
              item-text="Nombre"
              item-value="CanalId"
              append-icon="keyboard_arrow_down"
              label="Canal"
              hint="Canal"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-select
              :items="periodoOptions"
              v-model="periodoSelected"
              item-text="NombreMes"
              item-value="FechaFin"
              append-icon="keyboard_arrow_down"
              label="Periodo"
              hint="Periodo"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-radio-group v-model="tipoProductoSelected">
              <template v-slot:label>
                <div>Tipo producto</div>
              </template>
              <v-radio
                v-for="item in setTipoProductoOptions"
                :key="item.Value"
                :label="item.DisplayText"
                :value="item.Value"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <v-row>
            <v-autocomplete
              :items="asociados"
              v-model="asociadoSelected"
              item-text="Nombre"
              item-value="AsociadoId"
              placeholder="Asociado"
              append-icon="keyboard_arrow_down"
              hint="Asociado"
              persistent-hint
              clearable
            ></v-autocomplete>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="10">
      <v-progress-linear indeterminate color="primary" v-if="processing"></v-progress-linear>
      <v-tabs>
         <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>Gráfica</v-tab>
        <v-tab>Reporte</v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div ref="amchart" style="height: 45vh;"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-data-table :headers="headers" :items="chartData" :items-per-page="10">
                <template v-slot:item.Monto="{ item }">{{item.Monto | numeric}}</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-alert
        dense
        type="warning"
        text
        v-show="!processing && isChartEmpty"
      >No existe información para esta gráfica</v-alert>
    </v-col>
  </v-row>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import apiHome from "@/api/home";
import apiEmpleado from "@/api/empleados";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default {
  props: ["tipoProductoOptions"],
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      canalSelected: null,
      periodoSelected: null,
      setTipoProductoOptions: [{ Value: "Todos", DisplayText: "Todos" }],
      tipoProductoSelected: "Todos",
      asociadoSelected: null,
      headers: [
        { text: "PERIDO", value: "Periodo" },
        { text: "CONCEPTO", value: "Concepto" },
        { text: "MONTO", value: "Monto" },
      ],
      canalOptions:[],
      asociados:[],
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    asociadoOptions() {
      return this.$store.getters.asociados.filter(
        (c) => c.CanalId == this.canalSelected
      );
    },
    corteId() {
      return this.$store.getters.user.corteId;
    }
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        let conceptos = [];
        var periodos = [];
        dataParam.forEach(function (row) {
          if (periodos.indexOf(row.Periodo) === -1) {
            periodos.push(row.Periodo);
          }
          if (conceptos.indexOf(row.Concepto) === -1) {
            conceptos.push(row.Concepto);
          }
        });

        periodos.forEach(function (d) {
          let rows = dataParam.filter((f) => f.Periodo === d);
          let newRow = {
            Periodo: d,
            Total: 0,
          };
          let monto = 0;
          conceptos.forEach(function (c) {
            let dc = rows.filter((f) => f.Periodo === d && f.Concepto === c);
            if (dc[0]) {
              newRow[c] = dc[0].Monto;
              monto += dc[0].Monto;
            }
          });
          newRow["Total"] = monto;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Periodo";
        categoryAxis.title.text = "Periodo";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        conceptos.forEach(function (c) {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "Periodo";
          series.dataFields.valueY = c;
          series.name = c;
          series.stacked = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        });

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Periodo";
        lineSeries.dataFields.valueY = "Total";
        lineSeries.name = "Total";
        lineSeries.strokeWidth = 3;
        var circleBullet = lineSeries.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.tooltipText = "{name}: [bold]{valueY}[/]";

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.periodoSelected && this.tipoProductoSelected) {
        this.processing = true;
        let param = {
          FechaFin: this.periodoSelected,
          TipoProducto: this.tipoProductoSelected,
          AsociadoId: this.asociadoSelected,
          CanalID: this.canalSelected,
        };

        apiDashboard
          .getGetChartComparacionComisionesGeneradas(param)
          .then((response) => {
            this.chartData = response.data;
            this.generateChart(this.chartData);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
    getCanales() {

        apiHome.getCanalesExternosGestionados(this.corteId).then((response) => {
        this.canalOptions = response.data;
      });

    },
    async getAsociados(){
      
      const id = this.corteId?this.corteId:0;

      let param = {
        CanalesId:[this.canalSelected],
        GruposComisionId: [],
        CorteId:id
        };

      const response = await apiEmpleado.getAsociadosPorCanales(param);

      this.asociados = response.data
    },
  },
  watch: {
    corteId: function () {
      this.getCanales();
    },
    canalSelected: function () {
      this.getAsociados();
      this.refresh();
    },
    periodoSelected: function () {
      this.refresh();
    },
    tipoProductoSelected: function () {
      this.refresh();
    },
    asociadoSelected: function () {
      this.refresh();
    },
    
  },
  mounted() {
    if (this.canalOptions.length > 0) {
      this.canalSelected = this.canalOptions[0].CanalId;
    }
    if (this.periodoOptions.length > 0) {
      this.periodoSelected = this.periodoOptions[0].FechaFin;
    }
    if (this.tipoProductoOptions.length > 0) {
      this.tipoProductoOptions.forEach((item) => {
        this.setTipoProductoOptions.push(item);
      });
      this.tipoProductoSelected = this.tipoProductoOptions[0].Value;
    }
    this.refresh();
    this.getCanales();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>